<template>
  <div>
    <h1 style="color: #f0f0f0">警用无人机驾驶航空器登记表</h1>
    <table border="1">
      <tr>
        <th>购置部门</th>
        <td colspan="2"><input type="text" placeholder="必填项" /></td>
        <th>购置年月</th>
        <td class="bg" style="padding: 0 10px">
          <DatePicker
            type="date"
            placeholder="必填项"
            :editable="false"
          ></DatePicker>
        </td>
        <th>联系人</th>
        <td><input type="text" placeholder="必填项" /></td>
        <th>手机号</th>
        <td><input type="text" placeholder="请输入手机号码" /></td>
      </tr>
      <tr>
        <th>使用部门</th>
        <td colspan="2"><input type="text" placeholder="必填项" /></td>
        <th>购置年月</th>
        <td class="bg"></td>
        <th>使用人</th>
        <td><input type="text" placeholder="必填项" /></td>
        <th>手机号</th>
        <td><input type="text" placeholder="请输入手机号码" /></td>
      </tr>
      <tr>
        <th rowspan="5">飞行平台</th>
        <th>型号</th>
        <td class="bg">
          <Select v-model="model1">
            <Option v-for="item in select1" :value="item" :key="item.index">{{
              item
            }}</Option>
          </Select>
        </td>
        <td colspan="2" class="bg" style="color: #00f">
          新增型号<span style="color: red; font-size: 12px"
            >（请谨慎添加，添加后不可删除）</span
          >
        </td>
        <th>生产企业</th>
        <td colspan="3">
          <input type="text" />
        </td>
      </tr>
      <tr>
        <td class="bg" colspan="2"><input type="radio" name="type" />固定翼</td>
        <td class="bg" colspan="2"><input type="radio" name="type" />多旋翼</td>
        <td class="bg" colspan="2"><input type="radio" name="type" />直升机</td>
        <td class="bg" colspan="2"><input type="radio" name="type" />其他</td>
      </tr>
      <tr>
        <th>
          <p>固定翼翼展</p>
          <p>（毫米）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>多旋翼旋翼数量</p>
          <p>（个）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>多旋翼轴距</p>
          <p>（毫米）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>直升机翼间距</p>
          <p>（毫米）</p>
        </th>
        <td><input type="text" /></td>
      </tr>
      <tr>
        <th>
          <p>续航时间</p>
          <p>（分钟）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>最大航程</p>
          <p>（公里）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>实用升限</p>
          <p>（米）</p>
        </th>
        <td><input type="text" /></td>
        <th colspan="2"></th>
      </tr>
      <tr>
        <th>
          <p>平台自重</p>
          <p>（千克）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>最大起飞重量</p>
          <p>（千克）</p>
        </th>
        <td><input type="text" /></td>
        <th>
          <p>最大任务荷载</p>
          <p>（千克）</p>
        </th>
        <td><input type="text" /></td>
        <th colspan="2"></th>
      </tr>
      <tr>
        <th rowspan="3">任务设备</th>
        <th>类别1</th>
        <td class="bg">
          <Select v-model="model1">
            <Option v-for="item in select2" :value="item" :key="item.index">{{
              item
            }}</Option>
          </Select>
        </td>
        <th>型号</th>
        <td><input type="text" /></td>
        <th>生产企业</th>
        <td colspan="3">
          <input type="text" />
        </td>
      </tr>
      <tr>
        <th>类别2</th>
        <td class="bg">
          <Select v-model="model1">
            <Option v-for="item in select2" :value="item" :key="item.index">{{
              item
            }}</Option>
          </Select>
        </td>
        <th>型号</th>
        <td><input type="text" /></td>
        <th>生产企业</th>
        <td colspan="3">
          <input type="text" />
        </td>
      </tr>
      <tr>
        <th>类别3</th>
        <td class="bg">
          <Select v-model="model1">
            <Option v-for="item in select2" :value="item" :key="item.index">{{
              item
            }}</Option>
          </Select>
        </td>
        <th>型号</th>
        <td><input type="text" /></td>
        <th>生产企业</th>
        <td colspan="3">
          <input type="text" />
        </td>
      </tr>
      <tr>
        <th rowspan="2">飞控系统</th>
        <th>飞控序列号</th>
        <td colspan="3">
          <input type="text" placeholder="必填项" />
        </td>
        <th>生产企业</th>
        <td colspan="3">
          <input type="text" />
        </td>
      </tr>
      <tr>
        <th>导航方式</th>
        <td class="bg" colspan="2"><input type="checkbox" />GPS</td>
        <td class="bg" colspan="2"><input type="checkbox" />北斗</td>
        <td class="bg" colspan="2"><input type="checkbox" />惯导</td>
        <td class="bg"><input type="checkbox" />其他</td>
      </tr>
      <tr>
        <th>数传电台</th>
        <th>型号</th>
        <td colspan="3"><input type="text" /></td>
        <th>生产企业</th>
        <td colspan="3"><input type="text" /></td>
      </tr>
    </table>
    <div class="btns">
      <button @click="btn">打印登记表</button>
      <button>提交转发送</button>
      <button @click="$router.go(-1)">返回</button>
      <button>保存转审查</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model1: "",
      select1: [
        "PHANTOM 4 RTK",
        "M300 RTK",
        "Mavic2 行业变焦",
        "Mavic2 行业双光",
        "Mavic2 行业进阶",
        "M210 v2",
        "M600 pro",
      ],
      type: 0,
      select2: ["侦察监视", "广播", "打击", "投送", "其他"],
    };
  },
  methods: {
    btn() {
      alert("无权限");
    },
  },
};
</script>
<style scoped lang="scss">
* {
  text-align: center;
}
table {
  width: 100%;
  margin: 50px auto;
  border-collapse: collapse;
  font-size: 16px;
  td {
    width: 188px;
    height: 40px;
  }
  input {
    outline: none;
    width: 100%;
    height: 100%;
    border: 0;
    text-align: left;
    padding-left: 5px;
    &::-webkit-input-placeholder {
      color: #c5c8ce;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #c5c8ce;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #c5c8ce;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #c5c8ce;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 5px;
  }
  th {
    background-color: #c5d6d5;
  }
  .bg {
    background-color: #c5d6d5;
  }
}
.btns {
  button {
    background-color: #667c4d;
    color: #fff;
    padding: 5px 25px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>